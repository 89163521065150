import { Button, CircularProgress, List, ListItem, ListItemButton, ListItemText, Paper, Tooltip } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { useAuth } from "react-oidc-context";
import React, { useEffect, useState } from "react";
import { Recipe } from "../../model/Recipe";
import RecipeLine from "../../pages/meal_planner/recipes/RecipeLine";
import { useNavigate } from "react-router-dom";
import { Category } from "../../model/Category";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { randomFromInterval } from "../../Common";
import RecipeCategoryFilter from "./RecipeCategoryFilter";

export default function RecipeBrowser(props: { applyRecipe?: (recipe: Recipe) => void }) {

    const auth = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [recipes, setRecipes] = useState<Recipe[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedCategory) {
            setLoading(true);
            ApiService.getRecipesOfCategory(auth, selectedCategory.id, 0)
                .then((response) => {
                    setRecipes(response.data)
                })
                .catch()
                .finally(() => {
                    setLoading(false);
                })
        }
    }, [selectedCategory])

    useEffect(() => {
        setLoading(true);
        ApiService.getCategories(auth).then(response => {
            setCategories(response.data);
        })
            .catch()
            .finally(() => {
                setLoading(false);
            })
    }, []);

    function handleOpenRecipeCreation(): any {
        navigate('/add_recipe');
    }

    function randomize(categoryId: number) {
        setLoading(true);
        ApiService.getRecipesOfCategory(auth, categoryId, 0)
            .then((response) => {
                const recipesOfCategory = response.data;
                const randomizedRecipe = recipesOfCategory[randomFromInterval(0, response.data.length - 1)];
                if (props.applyRecipe) {
                    props.applyRecipe(randomizedRecipe);
                }
            })
            .catch()
            .finally(() => {
                setLoading(false);
            })
    }

    const randomizeIcon = (categoryId: number) => props.applyRecipe ?
        <Tooltip title={t('Apply random recipe of this category')}>
            <ArrowBackIcon
                onClick={() => randomize(categoryId)}
            />
        </Tooltip>
        : <></>;

    const progress = loading ? <ListItem><CircularProgress sx={{ my: { md: 10 } }} /></ListItem> : <></>;

    return (
        <Paper elevation={1} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <RecipeCategoryFilter setSelectedCategory={setSelectedCategory} />
            <List>
                {progress}
                {recipes.map(recipe => {
                    return (
                        <RecipeLine recipe={recipe} key={recipe.name} onApplyRecipe={props.applyRecipe} />
                    )
                })}
            </List>
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={handleOpenRecipeCreation}>{t("Add")}</Button>
        </Paper>
    );
}