import { Badge, CircularProgress, Paper, Typography } from '@mui/material';
import { PickersDay, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Day as MealsOfDay } from "../../../model/Day"
import { useAuth } from 'react-oidc-context';
import { ApiService } from '../../../services/ApiService';

interface PlannerParams {
    date: Date | null,
    onDateChange: (newDate: Date | null) => void
}

export default function Planner({ date, onDateChange }: PlannerParams) {
    const [highlightedDays, setHighlightedDays] = useState([] as number[]);
    const [month, setMonth] = useState(date ?? new Date());
    const [loading, setLoading] = useState(false);

    const auth = useAuth();

    useEffect(() => {
        const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
        const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 23, 59);

        setLoading(true);
        ApiService.getMealsOfDays(monthStart, monthEnd, auth)
            .then(response => {
                extractHighlightedDays(response.data);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [month]);

    function extractHighlightedDays(mealsOfDay: MealsOfDay[]) {
        let days = mealsOfDay.map(day => {
            return new Date(day.date).getDate();
        });

        days = days.filter((value, index) => days.indexOf(value) === index);
        if (days) {
            setHighlightedDays(days);
        }
    }

    function handleMonthChange(month: Date): void {
        setMonth(month);
    }

    const selectedDays = (datePickerProps: PickersDayProps<Date> & { highlightedDays?: number[] }) => {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = datePickerProps;

        const isSelected =
            !datePickerProps.outsideCurrentMonth &&
            highlightedDays.indexOf(datePickerProps.day.getDate()) >= 0;

        return (
            <Badge
                key={datePickerProps.day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🌚' : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }
    const progress = loading ? <CircularProgress sx={{ my: { md: 10 } }} /> : <></>;

    return <>
        <Paper elevation={0} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {loading ? progress :
                    <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo='day'
                        value={date}
                        onMonthChange={handleMonthChange}
                        onChange={(newValue) => {
                            onDateChange(newValue);
                        }}
                        slots={{
                            day: selectedDays
                        }}
                        slotProps={{
                            day: {
                                highlightedDays,
                            } as any
                        }}
                    />
                }
            </LocalizationProvider>
        </Paper>
    </>
}