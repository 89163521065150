import { Recipe } from "../../../model/Recipe";
import { Button, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../../services/ApiService";
import { Ingredient } from "../../../model/Ingredient";
import { useAuth } from "react-oidc-context";
import { ingredientWithUnitValue } from "../../../Common";
import { useTranslation } from "react-i18next";

export default function RecipeDetails() {

    const auth = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const recipeId = params.id;
    const { t } = useTranslation();

    const [recipe, setRecipe] = useState<Recipe>();
    const [ingredients, setIngredients] = useState([] as Ingredient[]);

    useEffect(() => {
        ApiService.getIngredients().then(response => {
            setIngredients(response.data);
        })
    }, [])

    useEffect(() => {
        if (recipeId) {
            ApiService.getRecipe(recipeId as any as number, auth).then((response) => {
                setRecipe(response.data);
            });
        }
    }, [recipeId]);


    if (!recipe) return (<></>);

    function edit(event: any): void {
        navigate('/edit_recipe/' + recipeId, { state: recipe });
    }

    return (
        <Stack spacing={2}>
            <Paper>{recipe.description}</Paper>
            <Typography variant="h6" gutterBottom mb={3}>
                {t(recipe.name)}
            </Typography>
            <Paper elevation={5}>
                <List>
                    {t("Ingredients")}
                    {recipe.ingredients.map((recipeIngredient, index) => {
                        const ingr = ingredients.find(ingr => ingr.id == recipeIngredient.ingredientId);
                        const ingredientName = ingr?.name;
                        const ingredientUnitValue = recipeIngredient.unitValue;

                        return (
                            <ListItem key={'ingredient-' + index}>
                                <ListItemText
                                    // primary={step}
                                    secondary={`- ${ingredientWithUnitValue(t(ingredientName ?? ''), ingredientUnitValue)}`} />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
            <Paper elevation={20}>
                <List>
                    {t("Steps")}
                    {recipe.steps.map((step, index) => {
                        return (
                            <ListItem key={'step-' + index}>
                                <ListItemText
                                    // primary={step}
                                    secondary={`${index + 1}. ${step}`} />
                            </ListItem>
                        )
                    })}
                </List>
            </Paper>
            <div>
            <Button variant="contained" sx={{ mt: 2 }} onClick={edit}>{t("Edit")}</Button>
            </div>
        </Stack>
    )
}