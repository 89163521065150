import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigation } from '../components/Navigation';
import NotFoundPage from './NotFoundPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppUrlListener from '../AppUrlListener';
import RecipeDetails from './meal_planner/recipes/RecipeDetails';
import { Main } from './meal_planner/Main';
import AddIngredient from './ingredients/AddIngredient';
import ListIngredients from './ingredients/ListIngredients';
import UpdateIngredient from './ingredients/UpdateIngredient';
import RecipeBrowser from '../components/recipe_browser/RecipeBrowser';
import AddRecipe from './add_recipe/AddRecipe';
import EditRecipe from './edit_recipe/EditRecipe';
import ShoppingList from './shopping_list/ShoppingList';
import CategoryBrowser from './categories/CategoryBrowser';
import AddCategory from './categories/AddCategory';
import OneColumnPage from '../components/OneColumnPage';


const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#f5c98f' },
    secondary: { main: '#f0e08e' },
    error: { main: '#f5968f' },
    warning: { main: '#f5968f' },
    success: { main: '#8fbbf5' },
  }
});

export default function Layout() {


  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Navigation />
        <OneColumnPage>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<Main />} />
            <Route path="/recipe/:id" element={<RecipeDetails />} />
            <Route path="/recipes" element={<RecipeBrowser />} />
            <Route path="/add_recipe" element={<AddRecipe />} />
            <Route path="/edit_recipe/:id" element={<EditRecipe />} />
            <Route path="/ingredients" element={<ListIngredients />} />
            <Route path="/add_ingredient" element={<AddIngredient />} />
            <Route path="/update_ingredient/:id" element={<UpdateIngredient />} />
            <Route path="/shopping_list" element={<ShoppingList />} />
            <Route path="/categories" element={<CategoryBrowser />} />
            <Route path="/add_category" element={<AddCategory />} />
          </Routes>
        </OneColumnPage>
        <AppUrlListener></AppUrlListener>

      </BrowserRouter>

    </ThemeProvider>
  );
}
