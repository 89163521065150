import { useEffect, useState } from "react";
import { Category } from "../../model/Category";
import { Chip } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { useAuth } from "react-oidc-context";

export default function RecipeCategoryFilter(props: { setSelectedCategory: (category: Category) => void }) {

    const [categories, setCategories] = useState<Category[]>([]);
    const auth = useAuth();

    useEffect(() => {
        ApiService.getCategories(auth).then(response => {
            setCategories(response.data);
        })
            .catch()
            .finally(() => {
            })
    }, []);


    return (<>
        {categories.map(category => {
            return <Chip label={category.name} sx={{ ml: 1 }}
                onClick={() => { props.setSelectedCategory(category) }}
            />
        })}
    </>);
}